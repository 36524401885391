.footer {
  $this: &;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  background-color: $white;
  @include ready {
    @include tr(opacity .3s, visibility .3s); }
  @include sm {
    position: relative;
    z-index: 1; }
  &.is-above {
    z-index: 20; }
  &.is-visible {
    visibility: visible;
    opacity: 1; }

  &__inner {
    @include flex(flex-start, normal, row, wrap); }
  &__left {
    padding-left: 50px;
    width: percentage(640/1800);
    @include xl {
      padding-left: 30px; }
    @include lg {
      padding-left: 15px; }
    @include md;

    @include sm {
      width: 100%;
      padding-left: 0; } }
  &__right {
    width: percentage(1160/1800);
    // +flex(center, normal, column)
    border-top: 1px solid  $gray-lighten;
    @include css-lock(0, 165, 991, 1399, padding-left);
    position: relative;
    padding-top: 8%;
    padding-bottom: 8%;
    @include md {
      padding-top: 60px;
      padding-bottom: 60px;
      display: block; }
    @include sm {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px; } }
  &__form {
    max-width: 780px; }
  &__bottom {
    color: $gray-light;
    text-transform: uppercase;
    font-size: 11px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    @include css-lock(15, 180, 991, 1399, padding-left);
    padding-right: 50px;
    @include flex(space-between, center);
    @include xl {
      padding-right: 30px; }
    @include lg {
      padding-right: 15px; }
    @include sm {
      position: static;
      display: block; } }
  &__copy {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    @include before {
      border-top: 1px solid $gray-lighten;
      top: 0;
      left: 0;
      position: absolute;
      width: 130px; }
    @include xs {
      padding-top: 10px;
      padding-bottom: 10px; } }
  &__auth {
    @include flex(flex-start, center); }
  &__auth-logo {
    background-color: $black;
    margin-right: 5px;
    @include flex(center, center);
    width: 40px;
    height: 40px;
    color: $white;
    .icon {
      font-size: 20px; } } }
