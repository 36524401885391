.grid {
  $this: &;
  $gutter: 30px;
  $gutter-xl: 15px;
  $gutter-lg: 10px;
  @include flex(flex-start, normal, row, wrap);
  &__left,
  &__right {
    width: 50%;
    @include flex(flex-start, normal, column);
    @include sm {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  &__left {
    padding-right: 15px;
    @include sm {
      margin-bottom: 25px; } }
  &__left-center {
    align-items: center;
    @include sm {
      align-items: normal; } }
  &__right {
    padding-left: 15px; }
  &__row {
    margin-left: -$gutter;
    margin-right: -$gutter;
    @include flex(flex-start, normal, row, wrap);
    @include xl {
      margin-left: -$gutter-xl;
      margin-right: -$gutter-xl; }
    @include lg {
      margin-left: -$gutter-lg;
      margin-right: -$gutter-lg; } }
  &__col {
    padding-left: $gutter;
    padding-right: $gutter;
    margin-bottom: $gutter * 2;
    @include xl {
      padding-left: $gutter-xl;
      padding-right: $gutter-xl;
      margin-bottom: $gutter-xl * 2; }
    @include lg {
      padding-left: $gutter-lg;
      padding-right: $gutter-lg;
      margin-bottom: $gutter-lg * 2; } }
  &__col-3 {
    width: 33.33333%;
    @include md {
      width: 50%; }
    @include xs {
      width: 100%; } }

  &--block {
    display: block;
    // padding-left: $gutter
 } }    // padding-right: $gutter

