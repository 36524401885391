.contacts {
  color: $white;
  font-weight: 500;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  // margin-bottom: -152px
  @include xl {
    padding-left: 30px;
    padding-right: 30px;
 }    // margin-bottom: -132px
  @include lg {
    padding-left: 15px;
    padding-right: 15px;
 }    // margin-bottom: -112px
  @include md {
 }    // margin-bottom: -107px
  @include sm {
    margin-bottom: 0; }
  &__top {
    position: relative; }
  &__tabs {
    @include flex;
    position: relative;
    z-index: 2;
    @include sm {
      margin-left: -15px;
      margin-right: -15px; } }
  &__tab {
    display: block;
    text-align: center;
    width: 420px;
    padding: 35px 10px;
    position: relative;
    background-color: $gray;
    @include tr(background-color .3s);
    @include md {
      width: 350px;
      padding: 25px 10px; }
    @include sm {
      width: 50%;
      padding: 15px 10px; }
    @include before {
      @include arr(20, 10, $accent, b);
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 100%;
      display: none; }
    &.is-active {
      background-color: $accent;
      @include before {
        display: block; } }
    @include hover {
      @extend .contacts__tab.is-active;
      @include before {
        display: none; } } }
  &__panorama {
    background-color: $gray;
    margin-left: -50px;
    margin-right: -50px;
    padding-top: 40%;
    position: relative;
    overflow: hidden;
    // +clr
    @include xl {
      margin-left: -30px;
      margin-right: -30px; }
    @include lg {
      margin-left: -15px;
      margin-right: -15px; }
    @include hover {
      iframe {
        filter: blur(0); }
      img {
        display: none; } }
    iframe {
      @include coverdiv;
      .no-touch & {
        filter: blur(4px);
        @include tr(filter .3s); } }

    img {
      @include vcenter;
      z-index: 2;
      max-width: 30%;
      html:not(.no-touch) & {
        display: none; } }
    .tabs-item {
      @include coverdiv;
      z-index: 0; } }

  &__map {
    padding-top: 40%;
    position: relative;
    background-color: $gray;
    margin-left: -50px;
    margin-right: -50px;
    .tabs-item {
      @include coverdiv;
      z-index: 0; }
    @include xl {
      margin-left: -30px;
      margin-right: -30px; }
    @include lg {
      margin-left: -15px;
      margin-right: -15px; }
    @include sm {
      padding-left: 0;
      padding-right: 0;
      padding-top: 60%; }
    @include xs {
      padding-top: 70%; }
    iframe {
      @include coverdiv;
      z-index: 0; } }
  &__map-overlay {
    @include coverdiv;
    z-index: 1; }
  &__block {
    // position: relative
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    max-width: 840px;
    left: 50px;
    @include xl {
      left: 30px; }
    @include sm {
      left: 0;
      position: relative; }
    @include sm {
      // position: relative
      transform: translate(0, 0);
      height: auto;
      margin-left: -15px;
      margin-right: -15px; } }
  &__block-item {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    @include tr(opacity .6s);
    &.is-active {
      overflow: visible;
      max-height: none;
      opacity: 1;
      padding: 40px 100px;
      @include xl {
        padding: 30px 80px; }
      @include lg {
        padding: 20px 60px; }
      @include md {
        padding: 20px 40px; }
      @include sm {
        padding: 20px; }
      @include xs {
        padding: 10px; } } } }

.contacts-block {
  background-color: $accent;
  @include flex();
  @include xs {
    display: block; }
  &__title {
    color: #f6928f;
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__item {
    width: 33.33333%;
    @include xs {
      width: 100%;
      margin-bottom: 10px; }
    @include notlast {
      border-right: 1px solid #f6928f;
      padding-right: 30px;
      @include md {
        padding-right: 15px; }
      @include sm {
        padding-right: 5px; }
      @include xs {
        padding-right: 0;
        border-right: 0; } }
    @include notfirst {
      padding-left: 30px;
      @include md {
        padding-left: 15px; }
      @include sm {
        padding-left: 5px; }
      @include xs {
        padding-left: 0; } } } }

.main-contacts {
  @include flex(space-around);
  @include xs {
    display: block; }
  .main-contacts-block {
    padding-left: 5px;
    padding-right: 5px;
    @include xs {
      @include notlast {
        margin-bottom: 10px; } } } }

.main-contacts-block {
  text-align: center;
  font-size: 18px;
  @include md {
    font-size: $fz; }
  // +sm
  //   font-size: $fz
  &__text {
    text-transform: uppercase; }
  &__phones {
    font-weight: 700;
    color: $black; }
  &__email {
    a {
      @include nav-link; } } }

