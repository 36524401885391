.select {
  position: relative;
  select {
    position: absolute;
    visibility: hidden;
    opacity: 0; } }

.custom-select {
  $this: &;
  text-transform: uppercase;
  position: relative;
  &.is-open {
    #{$this}__panel {
      visibility: visible;
      opacity: 1; } }
  &__opener {
    background-color: $gray-lighten;
    padding: 1.1em;
    height: 3.55em;
    padding-right: 30px;
    position: relative;
    @include text-overflow;
    @include before {
      @include icon-arrow(10, 10, 1, currentColor, -45);
      top: 1.2em;
      right: 20px;
      pointer-events: none;
      z-index: 1;
      position: absolute; } }
  &__panel {
    background-color: $gray-lighten;
    position: absolute;
    left: 0;
    width: 100%;
    top: calc(100% + 10px);
    z-index: 5;
    max-height: 250px;
    padding-right: 0;
    // overflow: auto
    visibility: hidden;
    opacity: 0;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    @include ready {
      @include tr(opacity .3s, visibility .3s); }
    &.scrollbar {
      @include scrollbar($gray-lighten);
      .scrollbar__inner {
        padding-right: 0; } } }
  &__option {
    padding: 1.2em;
    user-select: none;
    cursor: pointer;
    @include tr(background-color .3s, color .3s);
    @include hover {
      background-color: $white-dark;
 }      // color: $white
    &[data-value="placeholder"] {
      display: none; } } }



