.gallery {
  @include flex(center, normal, row, wrap);
  margin-left: -15px;
  margin-right: -15px;
  @include md {
    margin-left: -10px;
    margin-right: -10px; }
  @include xs {
    margin-left: -5px;
    margin-right: -5px; }
  &__item {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    width: calc(25% - 30px);
    position: relative;
    .checkbox {
      position: absolute;
      z-index: 1;
      bottom: 15px;
      right: 15px;
      visibility: hidden;
      opacity: 0;
      @include sm {
        right: 10px;
        bottom: 10px; }
      @include xs {
        right: 5px;
        bottom: 5px; }
      html:not(.no-touch) & {
        visibility: visible;
        opacity: 1; } }
    @include hover {
      .checkbox {
        opacity: 1;
        visibility: visible; } }
    @include lg {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      width: calc(33.33333% - 20px); }
    @include sm {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      width: calc(50% - 10px); }
    @include xs {
      width: 100%;
      margin-left: 0;
      margin-right: 0; } } }

.gallery-item {
  display: block;
  position: relative;
  background-color: $white;
  border: 1px solid  $gray-lighten;
  @include tr(box-shadow .3s);
  @include hover {
    box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.25); }
  .gallery__item & {
    width: 100%; }
  &__img {
    padding-top: 230px;
    position: relative;
    display: block;
    width: 100%;
    img {
      position: absolute;
      @include vcenter;
      max-height: calc(100% - 20px);
      max-width: calc(100% - 20px); } }
  &__title {
    border-bottom: 1px solid $gray-lighten;
    margin-bottom: 15px;
    padding-bottom: 8px;
    display: block; }
  &__content {
    padding: 15px 10px;
    display: block; }

  &__price-list {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    @include xl {
      padding-left: 0;
      padding-right: 0; } } }
