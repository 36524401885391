@mixin scrollbar($background: $white-darken) {
  &::-webkit-scrollbar {
    width: 6px; }
  &::-webkit-scrollbar-track {
    background-color: $gray-light;
    box-shadow: inset 2px 0 0px $background, inset -2px 0 0px $background;
    outline: none; }
  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    @include tr(background-color .3s);
    @include hover {
      background-color: $gray; } } }

.scrollbar {
  position: relative;
  overflow: auto;
  @include scrollbar;
  &__inner {
    padding-right: 35px; }
  &__track {
    position: absolute;
    width: 6px;
    bottom: 0;
    top: 0;
    right: 0;
    @include before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 2px;
      background-color: $gray-light; } }
  &__bar {
    position: absolute;
    width: 100%;
    right: 0;
    background-color: $gray; } }

.vs-scrollbar {
  position: fixed;
  z-index: 100;
  width: 6px;
  right: 0;
  bottom: 0;
  top: 0;
  &.vs-horizontal {
    display: none; } }

.vs-scrolldrag {
  width: 100%;
  background-color: $gray; }
