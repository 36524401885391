.pagination {
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
    @include xs {
      margin-left: 0;
      margin-right: 0; }
    .icon {
      font-size: 8px;
      color: $black;
      @include xs {
        font-size: 5px; }
      @include xss {
        font-size: 3px; } }
    &:first-child,
    &:last-child {
      padding-top: .4em;
      a {
        @extend .arrow;
        margin-left: 0;
        margin-right: 0; } }
    &:first-child {
      @include hover {
        a {
          background-color: transparent; } } }

    &:last-child {
      @extend .arrow--next;
      @include hover {
        a {
          background-color: transparent; } } } }


  .is-active {
    color: $white;
    background-color: $accent; }

  a {
    display: block;
    margin-left: 0;
    margin-right: 0;
    padding: 10px 17px;
    background-color: $gray-lighten;
    @include tr(color .3s, transform .3s, background-color .3s);
    @include hover {
      color: $white;
      background-color: $accent; }
    @include xs {
 } }      // padding: 5px
  .is-inactive {
    pointer-events: none;
    background-color: transparent;
    @include xs {
      padding: 0; } } }
