.popup {
  position: fixed;
  z-index: 120;
  background-color: rgba($black, .7);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px;
  overflow: auto;
  @include vertical;
  visibility: hidden;
  opacity: 0;
  @include ready {
    @include tr(opacity .3s, visibility .3s); }
  &.is-active {
    visibility: visible;
    opacity: 1; }
  &[data-popup="lightbox"] {
    z-index: 125; }
  &__inner {
    font-size: $fz;
    position: relative;
    background-color: $white;
    width: 100%;
    max-width: 1480px;
    padding: 75px 35px;
    display: inline-block;
    vertical-align: middle;
    @include xl {
      padding: 50px 30px; }
    @include lg {
      padding: 40px 30px;
      padding-top: 50px; }
    @include md {
      padding: 30px;
      padding-top: 50px; }
    @include sm {
      padding: 20px;
      padding-top: 50px;
      font-size: $fz-sm; } }
  &__close {
    @include icon-close(20, 1, currentColor);
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px; }
  &__title {
    margin-bottom: 50px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } } }

.popup-gallery {
  $this: &;
  &__title {
    margin-bottom: 45px;
    @include xl {
      margin-bottom: 30px; }
    @include lg {
      margin-bottom: 20px; }
    @include md {
      margin-bottom: 10px; } }
  &__inner {
    @include flex(flex-start, normal, row, wrap); }
  &__left {
    padding-right: 35px;
    padding-top: 80px;
    width: percentage(475/1290);
    @include flex(flex-start, normal, column);
    @include lg {
      padding-right: 20px;
      padding-top: 70px; }
    @include md {
      padding-right: 10px;
      padding-top: 50px; }
    @include sm {
      padding-right: 0;
      width: 100%;
      margin-bottom: 25px;
      padding-top: 0; } }
  &__right {
    padding-left: 35px;
    width: percentage(815/1290);
    @include lg {
      padding-left: 20px; }
    @include md {
      padding-left: 10px; }
    @include sm {
      padding-left: 0;
      width: 100%; } }
  &__img-lg-one {
    padding-top: 40%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    @include md {
      padding-top: 50%; }
    @include xs {
      padding-top: 80%; } }
  &__img-sm-wrap {
    @include flex(flex-start, normal, row, wrap);
    margin-left: -25px;
    margin-right: -25px;
    @include lg {
      margin-left: -15px;
      margin-right: -15px; }
    @include md {
      margin-left: -10px;
      margin-right: -10px; }
    @include sm {
      margin-left: -5px;
      margin-right: -5px; } }
  &__img-lg {
    padding-top: 80%;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 200%;
    @include hover {
      img {
        opacity: 0; } }
    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__img-sm {
    width: 33.33333%;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
    @include lg {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px; }
    @include md {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px; }
    @include sm {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 5px; } }
  &__left-title {
    padding-bottom: 10px;
    border-bottom: 1px solid  $gray-lighten;
    margin-bottom: 10px;
    .title {
      margin-bottom: 6px; } } }

.popup-gallery-img {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  padding-top: 100%; }
