.clients {
  @include flex;
  @include sm {
    display: block; }
  &__list {
    width: percentage(440/1450);
    padding-left: 70px;
    padding-right: 15px;
    @include xl {
      padding-left: 40px; }
    @include lg {
      padding-left: 0; }
    @include sm {
      width: auto;
      margin-bottom: 15px; } }
  &__table {
    width: percentage(1010/1450);
    @include sm {
      width: auto; } } }

.clients-table {
  // display: table
  width: 100%;
  // border-collapse: collapse
  max-width: 870px;
  &__img {
    width: 60%;
    padding-top: 25%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    &.is-loaded {
      animation: none !important; } }
  &__tr {
    // display: table-row
    @include flex(flex-start, normal, row, wrap); }
  &__td {
    // display: table-cell
    // vertical-align: middle
    text-align: center;
    border: 1px solid $gray-lighten;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 33.33333%;
    @include flex(center, center);
    @include sm {
      padding-top: 20px;
      padding-bottom: 20px; } } }
