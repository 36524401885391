.header {
  $this: &;
  position: relative;
  z-index: 10;
  @include main-bg;
  color: $black;
  margin-bottom: $header-margin;
  height: $header-height;
  @include lg;
  @include md {
    margin-bottom: $header-margin-md;
    height: $header-height-md; }
  @include sm {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    height: $header-height-sm;
    background-color: $white;
    background-image: none; }
  @include xs {
    margin-bottom: $header-margin-xs;
    height: $header-height-xs; }

  @include after {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    width: 140px;
    height: 140px;
    background-color: $white-dark;
    @include md {
      width: 100px;
      height: 100px; }
    @include sm {
      width: 30px;
      height: 30px; }
    @include xs {
      width: 20px;
      height: 20px; } }
  &__rect {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    @include sm {
      display: none; } }

  &__inner {
    @include flex;
    position: relative;
    z-index: 1;
    max-height: 100%; }
  &__left {
    padding-right: 55px;
    padding-left: 50px;
    width: percentage(415/1800);
    opacity: 0;
    @include xl {
      padding-right: 25px;
      padding-left: 30px; }
    @include lg {
      padding-left: 15px; }
    @include md {
      padding-right: 15px;
      width: 30%; }
    @include xs {
      width: 50%; } }
  &__right {
    @include flex(flex-start, center);
    border-bottom: 1px solid  $gray-lighten;
    padding-top: 45px;
    padding-bottom: 45px;
    padding-right: 50px;
    width: percentage(1385/1800);
    position: relative;
    @include xl {
      padding-right: 30px; }
    @include lg {
      padding-right: 15px; }
    @include md {
      padding-top: 10px;
      padding-bottom: 10px; }
    @include sm {
      border-bottom: 0; }
    @include xs {
      justify-content: flex-end; } }
  &__nav {
    max-width: 250px;
    ul {
      @include flex(flex-start, noral, row, wrap); }
    li {
      width: 50%;
      opacity: 0; }
    @include sm {
      position: fixed;
      max-width: none;
      background-color: $white;
      padding: 15px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // height: 100vh
      // height: calc(var(--vh, 1vh) * 100)
      z-index: 10;
      overflow: auto;
      transform: translate(0, -100%);
      @include vertical;
      @include ready {
        @include tr(transform .3s); }
      &.is-active {
        transform: translate(0, 0); }
      li {
        width: auto; }
      ul {
        display: block; } } }
  &__nav-in {
    @include sm {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      font-size: $fz;
      .nav {
        margin-bottom: 15px; } } }
  &__nav-close {
    @include icon-close(20, 1, currentColor);
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    display: none;
    @include sm {
      display: block; } }
  &__logo {
    position: relative;
    padding-top: 60px;
    padding-left: 15px;
    .logo {
      position: relative;
      z-index: 1;
      max-width: 100%; }
    @include before {
      position: absolute;
      z-index: 0;
      background-color: $white;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0; }
    @include md {
      padding-top: 40px; }
    @include sm {
      // padding-top: 5px
      padding-top: 0;
      padding-left: 0;
      @include flex(center, center);
      height: 100%;
      @include before {
        display: none; } }
    @include xs {
      // padding-left: 10px
 } }      // padding-top: 10px
  &__address {
    text-align: right;
    transform: translate(0, 50px);
    @include md {
      transform: translate(0, 0); }
    @include sm {
      display: none; } }
  &__address-mob {
    display: none;
    @include sm {
      display: block; } }
  &__contacts {
    flex-grow: 1;
    opacity: 0;
    @include flex(space-between);
    padding-left: 10%;
    @include lg {
      padding-left: 15px; }
    @include md {
      display: block; }
    @include xs {
      display: none; } }
  &__contacts-mob {
    display: none;
    padding-top: 15px;
    padding-bottom: 15px;
    @include xs {
      display: block; } }
  &__burger {
    display: none;
    @include sm {
      display: block; } }

  &--transparent {
    background-color: transparent;
    background-image: none; }
  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; } }

.header-rect {
  width: 100px;
  height: 100%;
  // height: 160px

  background-color: $accent;
  @include md {
 }    // height: 160px
  @include sm {
    // height: 100px
    width: 80px; }
  @include xs {
    width: 60px;
 } }    // height: 70px

.header-address-item {
 }  // text-decoration: underline

.header-search {
  width: 60%;
  @include flex();
  @include md {
    // position: absolute
    // z-index: 1
    // top: calc(100% + 50px)
    // right: 0
    display: none; }
  &__icon {
    color: $black;
    margin-right: 15px;
    .icon {
      font-size: 20px;
      position: relative;
      top: .1em; } }
  &__input {
    flex-grow: 1;
    input {
      width: 100%;
      background-color: transparent;
      height: 25px;
      padding: 5px 0;
      border-bottom: 1px solid  $gray-lighten; } } }

.header-contacts {
  text-align: right;
  @include sm {
    @include flex(flex-start, center, row, wrap); }
  @include xs {
    display: block;
    text-align: center; } }

.header-phone {
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
  a {
    display: inline-block; }
  @include sm {
    margin-right: 10px;
    margin-bottom: 0; }
  @include xs {
    margin-right: 0; } }

.header-mail {
  text-decoration: underline;
  display: inline-block; }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: -14px;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }
  span:after {
    top: 18px; }
  span {
    position: relative;
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition: transform 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; } }

.burger-lg {
  width: 1em;
  height: 1em;
  font-size: 70px;
  text-transform: uppercase;
  color: $white;
  padding: 10px;
  background-color: $accent;
  display: block;
  text-align: center;
  @include sm {
    font-size: 50px; }
  &__title {
    display: block;
    margin-bottom: 5px;
    font-size: 0.17em; }
  &__burger {
    @extend .burger;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 0.6em;
    height: 0.23em;
    span {
      height: 1px;
      &::before, &::after {
        height: 1px;
        top: 6px; }
      &::after {
        top: 12px; } } } }
