.slider {
  &__wrap {
    position: relative;
    * {
      box-sizing: border-box; } }
  &__controls {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    @include md {
      padding-top: 15px;
      padding-bottom: 15px; } }

  &--gallery {
    &__wrap {
      .swiper-slide {
        height: auto; } } }
  &--works {
    &__wrap {
      .swiper-slide {
        height: auto; }
      .slider {
        &__controls {
          text-align: left;
          padding-top: 0;
          padding-bottom: 0; } } } } }

.arrow, .prev, .next {
  display: inline-block;
  position: relative;
  background-color: transparent;
  @include before;
  width: 75px;
  height: 25px;
  color: $black;
  @include tr(color .3s);
  margin-left: 20px;
  margin-right: 20px;
  @include hover {
    color: $accent; }
  @include before {
    background-color: currentColor;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0; }
  @include after {
    @include icon-arrow(10, 10, 1, currentColor, 45);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg) !important; }
  @include sm {
    width: 50px; }
  @include xs {
    width: 30px; }

  &--next, .next {
    transform: scaleX(-1); } }
