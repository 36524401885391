small {
  font-size: inherit; }

// h1, h2, h3, h4, h5, h6
//   font-weight: 400

.title {
  @include css-lock(21, 48, 320, 1399, font-size);
  color: $black;
  font-weight: 300;
  line-height: 1;
  display: block;
  a {
    @include tr(color .3s);
    @include hover {
      color: $accent; } }
  &--lg {
    @include css-lock(36, 90, 320, 1399, font-size); }
  &--h3 {
    @include css-lock(18, 24, 320, 1399, font-size); }
  &--h5 {
    @include css-lock(13, 14, 320, 1399, font-size); }
  &--ttu {
    text-transform: uppercase; }
  &--medium {
    font-weight: 500; }
  &--semi {
    font-weight: 600; }
  &--white {
    color: $white; } }
