.section {
  $this: &;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  @include xl {
    padding-top: 40px;
    padding-bottom: 40px; }
  @include lg {
    padding-top: 30px;
    padding-bottom: 30px; }
  // +md
  //   padding-top: 20px
  //   padding-bottom: 20px
  // +xs
  //   padding-top: 15px
  //   padding-bottom: 15px
  &__top {
    @include flex(space-between, flex-end);
    margin-bottom: 25px;
    @include sm {
      display: block; }
    .section__title {
      margin-bottom: 10px; } }
  &__top-block {
    display: block; }
  &__breadcrumbs {
    // margin-bottom: 30px
    // +md
 }    //   margin-bottom: 15px
  &__nav-title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 30px;
    color: $black;
    @include md {
      margin-bottom: 15px; } }
  &__title {
    margin-bottom: 45px;
    @include xl {
      margin-bottom: 35px; }
    @include lg {
      margin-bottom: 25px; }
    @include md {
      margin-bottom: 15px; } }
  &__more {
    text-align: center;
    padding-top: 20px;
    @include md {
      padding-top: 10px; } }
  &__filter {
    max-width: 820px;
    margin-bottom: 35px;
    @include md {
      margin-bottom: 15px; } }
  &__meta {
    width: 100%;
    padding-top: 20px;
    margin-bottom: 30px;
    @include md {
      margin-top: 0px;
      margin-bottom: 15px; } }

  &--white {
    background-color: $white; } }

.section-top {
  position: relative;
  z-index: 1;
  @include flex(flex-start, center, row, wrap);
  .shadow-rect {
    margin-top: 30px;
    @include lg {
      margin-top: 15px; } }
  @include xl {
    display: block; }
  &__title {
    max-width: 50%;
    margin-right: 45px;
    @include xl {
      margin-bottom: 15px;
      max-width: none;
      margin-right: 0; }
    @include lg {
      margin-bottom: 10px; } } }

.section-img {
  position: relative;
  padding-top: 25px;
  margin-left: -30px;
  padding-left: 100px;
  padding-right: percentage(510/1450);
  @include xl {
    margin-left: 0;
    padding-left: 70px; }
  @include lg {
    padding-left: 50px;
    padding-top: 15px; }
  @include md {
    padding-left: 30px; }
  @include sm {
    padding-left: 20px;
    padding-right: 0; }
  @include xs {
    padding-left: 10px;
    padding-top: 10px; }
  @include before {
    z-index: 0;
    background-color: $accent;
    width: percentage(241/970);
    height: 85%;
    position: absolute;
    top: 0;
    left: 0; }
  &__img {
    position: relative;
    z-index: 1;
    @include cover;
    width: 100%;
    padding-top: percentage(740/870); }
  &__block {
    position: absolute;
    background-color: $white;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    width: 50%;
    padding: 60px;
    z-index: 2;
    @include xl {
      padding: 40px; }
    @include lg {
      padding: 30px;
      width: 60%; }
    @include md {
      padding: 20px;
      width: 70%; }
    @include sm {
      width: auto;
      position: relative;
      transform: translate(0, 0);
      top: auto;
      right: auto; }
    @include xs {
      padding: 15px; }
    ul {
      li {
        &::before {
          content: "\2022";
          display: inline-block;
          margin-right: 5px; } } } }
  &__title {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; }
    @include sm {
      margin-bottom: 10px; } } }
