.fadeIn {
  animation-name: fadeIn; }
.fadeInUp {
  animation-name: fadeInUp; }
.bounceIn {
  animation: bounceInSide .7s; }


@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 50px); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes moveDown {
  0% {
    transform: translate(0, -100%); }
  100% {
    transform: translate(0, 0); } }

@keyframes bounceInSide {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3) rotate(90deg); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(90deg); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(90deg); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03) rotate(90deg); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97) rotate(90deg); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1) rotate(90deg); } }
