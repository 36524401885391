.hero {
  $this: &;
  padding-top: 20px;
  // overflow: hidden
  position: relative;
  z-index: 1;
  height: 90vh;
  @include flex(flex-start, normal, column);
  .container {
    width: 100%; }

  &__img {
    text-align: center;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%; }
  &__inner {
    position: relative; }

  &__img-in {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    padding-top: 35%;
    @include vcenter;
    width: 100%;
    @include xl {
      padding-top: 40%; }
    @include lg {
      padding-top: 50%; }
    @include md {
      padding-top: 0;
      height: 80%; } }


  &__content {
    @include flex(center, center);
    position: relative;
    flex-grow: 1;
    max-height: calc(100% - 44px); }
  &__title {}
  &__title-fixed-wrap {
    position: absolute;
    z-index: 0;
    left: 0;
    text-align: center;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    overflow: hidden; }
  &__title-fixed {
    @include css-lock(24, 130, 320, 1399, font-size);
    font-weight: 600;
    color: $gray-lighten; }

  &--common {
    height: auto;
    padding-top: 0;
    #{$this}__left-content {
      @include flex(center, normal, column);
      height: 60vh;
      width: 50%;
      padding-top: 25px;
      padding-bottom: 25px;
      @include sm {
        width: 100%;
        height: auto; } }
    #{$this}__right {
      position: absolute;
      top: -$header-margin;
      bottom: 0;
      right: 0;
      width: 50%;
      overflow: hidden;
      @include md {
        top: -$header-margin-md; }
      @include sm {
        position: relative;
        // top: -$header-margin-xs
        top: auto;
        bottom: auto;
        right: auto;
        width: 100%;
        margin-bottom: 15px; } }
    #{$this}__right-normal {
      top: 0;
      @include md {
        top: 0; } }
    #{$this}__left {
      // padding-right: 50%
      padding-top: 20px; }

    #{$this}__bottom {
      @include cover;
      min-height: 235px;
      padding-top: 15px;
      padding-bottom: 15px;
      @include sm {
        min-height: 150px; } } } }


.hero-link {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  font-weight: 500;
  color: $black;
  text-transform: uppercase;
  z-index: 1;
  @include flex(center, center);
  @include sm {
    width: 80px; }
  @include xs {
    width: 60px; }
  a {
    @include nav-link;
    @include rotate(-90);
    display: inline-block;
    white-space: nowrap;
    // +tr(color .3s)
    // +hover
 }    //   color: $accent
  &--prev {
    left: 0; }
  &--next {
    right: 0; } }

.hero-imgs {
  $this: &;
  height: 100%;
  @include flex(flex-start, center, column);
  &__top {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-bottom: $header-margin;
    .shadow-rect {
      margin-bottom: 10px; }
    @include md {
      margin-bottom: $header-margin-md; }
    @include xs {
      margin-bottom: $header-margin-xs; } }
  &__top-link {}
  &__link {
    @include sm {
      margin-bottom: 50px; } }
  &__imgs {
    flex-grow: 1;
    // white-space: nowrap
    width: 100%;
    margin-right: -130px;
    @include flex(space-between, center);
    .slider__wrap {
        width: 100%; }
    @include sm {
      margin-right: 0; } }
  &--slider {
    #{$this}__imgs {
      margin-right: -250px;
      @include xl {
        margin-right: -130px; }
      @include lg {
 }        // margin-right: -130px
      @include sm {
        margin-right: 0; } } } }

.hero-img {
  @include flex(flex-end, center, column);
  text-align: center;
  @include hover {
    .hero-img__title {
      color: $accent; } }
  @include notlast {
    margin-right: 10px; }
  &::before, &::after {
    content: '';
    border-right: 1px solid  $gray-lighten;
    bottom: 100%;
    z-index: 0;
    height: 50vh;
    position: absolute; }
  &::before {
    left: 10%; }
  &::after {
    right: 10%; }
  &.is-loaded {
    position: relative; }
  &__img {
    display: block;
    margin-bottom: 5px;
    box-shadow: 0 0 10px rgba($black, .2);
    font-size: 0;
    // width: 100%
    img {
      min-width: 100px;
      min-height: 100px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      &.is-loaded {
        opacity: 1;
        position: static; } } }
  &__title {
    @extend .title;
    @extend .title--h5;
    @extend .title--medium;
    @extend .title--ttu;
    @include tr(color .3s); } }




