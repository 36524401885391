// +font('montserrat', 'montserrat-thin-webfont', 100)
@include font('montserrat', 'montserrat-light-webfont', 300);
// +font('montserrat', 'montserrat-regular-webfont', 400)
@include font('montserrat', 'montserrat-medium-webfont', 500);
@include font('montserrat', 'montserrat-semibold-webfont', 600);
@include font('montserrat', 'montserrat-bold-webfont', 700);

// +font('montserrat', 'montserrat-thinitalic-webfont', 100, italic)
@include font('montserrat', 'montserrat-lightitalic-webfont', 300, italic);
// +font('montserrat', 'montserrat-regularitalic-webfont', 400, italic)
// +font('montserrat', 'montserrat-mediumitalic-webfont', 500, italic)
// +font('montserrat', 'montserrat-semibolditalic-webfont', 600, italic)
// +font('montserrat', 'montserrat-bolditalic-webfont', 700, italic)

