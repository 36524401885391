@mixin main-bg {
  background-color: $white-darken;
  background-image: radial-gradient($white, $white-darken);
  background-position: center center;
  background-repeat: no-repeat; }

@mixin nav-link {
  display: inline-block;
  background-image: linear-gradient(to top,rgba($black,0.2) 0,rgba($black,0.2) 0),linear-gradient(to top,$black 0,$black 0);
  background-position: left bottom 2px,left bottom 2px;
  background-repeat: no-repeat;
  background-size: 100% 1px,0 1px;
  transition: none 300ms ease-in-out;
  transition-property: background-size,color;
  @include hover {
    background-size: 100% 1px,100% 1px;
    color: $black; }
  &.is-active {
    background-size: 100% 1px,100% 1px;
    color: $black; } }

body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  font-family: 'montserrat', Arial, sans-serif;
  font-weight: 300;
  color: $c-text;
  @include no-scroll;
  @include sm {
    font-size: $fz-sm; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-animation) {
    animation: fadeIn 1s; } }

.container {
  max-width: 1480px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  @include xl {
    padding-left: 30px;
    padding-right: 30px; }
  @include lg {
    padding-left: 15px;
    padding-right: 15px; }
  &--full {
    max-width: none;
    margin-left: 0;
    margin-right: 0; } }

.out {
  position: relative;
  z-index: 1;
  // min-height: 100vh
  // min-height: calc(var(--vh, 1vh) * 100)
  @include main-bg;
  @include before {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: $header-height + $header-margin + 20px;
    @include main-bg;
    z-index: 0; }
  @include sm {
    padding-top: $header-height-sm; }
  @include xs {
    padding-top: $header-height-xs; }
  .is-firefox & {
    @include tr(.1s transform); }
  &.is-loaded {
    background-color: transparent;
    background-image: none; }
  &.is-above {
    z-index: 25; }
  &--transparent {
    background-image: none;
    background-color: transparent;
    @include before {
      display: none; } } }


.main {
  display: block;
  @include main-bg;
  position: relative;
  z-index: 1;
  opacity: 0;
  // padding-top: 20px
  padding-top: 30px;
  @include md {
    padding-top: 0; }
  &.lazy {
    @include cover;
    @include lg {
      background-position: 0 50%; } }
  &--transparent {
    background-color: transparent;
    background-image: none; }
  &--has-header-absolute {
    padding-top: $header-height;
    @include md {
      padding-top: $header-height-md; }
    @include sm {
      padding-top: 0; } }
  &--full {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100); } }


.logo {
  font-size: 0;
  display: inline-block; }

.logo-text {
  font-family: serif;
  color: $black;
  line-height: 1.1;
  @include inline-flex(flex-start, center);
  span {
    font-family: 'montserrat', Arial, sans-serif;
    color: $accent; }
  &__icon {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: .8em;
    width: .8em;
    margin-right: .2em;

    @include after {
      width: .3em;
      height: .3em;
      background-color: $accent;
      display: inline-block; } }

  &__icon-arrow {
    border-left: .15em solid currentColor;
    border-bottom: .15em solid currentColor;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 75%;
    @include before {
      @include arr(.15, .15, $black, tl, em);
      position: absolute;
      bottom: -0.15em;
      left: 100%; }
    @include after {
      @include arr(.15, .15, $black, br, em);
      position: absolute;
      bottom: 100%;
      left: -0.15em; } }

  &--md {
    // font-size: 80px
    // +css-lock(21, 80, 320, 1399, font-size)
    font-size: 8.5vh;
    @include xss {
      font-size: 35px; } } }



.word {
  @include css-lock(60, 415, 320, 1399, font-size);
  line-height: .9;
  position: absolute;
  left: -50%;
  top: 0;
  opacity: .1;
  z-index: 0; }

.shadow-rect {
  box-shadow: 0px 7px 19px 0px rgba(0, 0, 0, 0.17), inset 0px 0px 7.28px 0.72px rgba(0, 0, 0, 0.28);
  height: 16px;
  width: 100%;
  background-color: $white;
  &--texture {
    @include image('metal-pattern.jpg');
    background-repeat: repeat;
    background-size: auto;
    position: relative;
    @include before {
      @include image('shadow-rect-side.png');
      position: absolute;
      top: -2px;
      left: -8px;
      height: 24px;
      width: 19px;
      z-index: 1; }
    @include after {
      @include image('shadow-rect-side.png');
      position: absolute;
      top: -2px;
      right: -8px;
      height: 24px;
      width: 19px;
      z-index: 1;
      transform: scaleX(-1); } } }

.bg-lines {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  max-width: 1450px;
  overflow: hidden; }

.bg-line {
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 1px solid  $gray-lighten;
  animation-name: moveDown;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  transform: translate(0, -100%);
  &:nth-child(1) {
    left: $line-left-1; }
  &:nth-child(2) {
    left: $line-left-2;
    animation-delay: 0.2s; }
  &:nth-child(3) {
    left: $line-left-3;
    animation-delay: 0.4s; }
  &:nth-child(4) {
    left: $line-left-4;
    animation-delay: 0.6s; } }

.link {
  text-transform: uppercase;
  font-weight: 500;
  color: $black;
  position: relative;
  @include tr(color .3s);
  @include hover {
    color: $accent; }
  @include after {
    width: 130px;
    border-top: 1px solid  $gray-lighten;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -.1em;
    margin-left: 15px;
    @include tr(border-color .3s);
    @include sm {
      width: 100px; }
    @include xs {
      width: 80px; } }
  &--ttn {
    text-transform: none; }
  &--black {
    color: $black;
    @include after {
      border-color: currentColor; } }
  &--no-hover {
    @include hover {
      color: initial; } } }

.banner {
  @include cover;
  color: $white;
  padding: 70px 40px 60px;
  height: 100%;
  @include flex(space-between, normal, column);
  @include xl {
    padding: 60px 40px; }
  @include lg {
    padding: 50px 30px; }
  @include md {
    padding: 30px; }
  @include sm {
    padding: 20px; }
  &__phones {
    margin-bottom: 15px; }
  &__phone {
    @extend .title;
    @extend .title--h3;
    @extend .title--white;
    @extend .title--medium;
    margin-bottom: 5px; }
  &__mail {
    font-weight: 500; }
  &__title {
    max-width: 60%;
    margin-bottom: 65%;
    @include md {
      max-width: none; }
    @include sm {
      margin-bottom: 30%; } } }

.stext {
  max-height: 295px;
  overflow: auto;
  h1, h2 {
    @extend .title;
    @extend .title--h3;
    @extend .title--ttu;
    @extend .title--medium;
    margin-bottom: 50px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  h2, h3, p, ol, ul {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  ul {
    list-style-type: disc;
    margin-left: 1.1em; }
  ol {
    list-style-type: decimal;
    margin-left: 1.1em; }
  a {
    text-decoration: underline;
    color: blue; } }

.side-line {
  border-left: 1px solid  $gray-lighten;
  top: -500px;
  position: absolute;
  bottom: 0;
  left: 100px;
  z-index: 2;
  @include r(1600) {
    display: none; }
  // +sm
  //   left: 80px
  // +xs
 }  //   left: 60px

.list {
  li {
    margin-bottom: 10px;
    &::before {
      content: "\2022";
      display: inline-block;
      margin-right: 5px; } } }

.tabs-item {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  &.is-active {
    max-height: none;
    opacity: 1;
    overflow: visible; } }

.side-btn {
  position: fixed;
  display: block;
  right: 0;
  top: $header-height + 270px;
  z-index: 110;
  text-transform: uppercase;
  background-color: $accent;
  color: $white;
  height: 50px;
  width: 270px;
  @include rotate(90);
  transform-origin: top right;
  font-weight: 700;
  @include md {
    top: $header-height-md + 270px; }
  @include xs {
    width: 230px;
    height: 30px; }
  .letter {
    @include rotate(-90);
    display: inline-block;
    margin-right: 1em; }
  &.is-bottom {
    z-index: 0; } }

