.nav {
  color: $black;
  font-weight: 600;
  text-transform: uppercase;
  li {
    display: inline-block;
    margin-bottom: 5px;
    @include sm {
      display: block; } }
  a {
    @include nav-link; }
  &--content {
    li {
      display: block; } }
  &--vert {
    text-align: center;
    li {
      display: block; } } }
