.error {
  max-width: 330px;
  padding-top: 5%;
  &__title {
    margin-bottom: 30px;
    font-weight: 600;
    color: $black;
    font-size: 90px;
    @include md {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 10px; } } }
