.btn {
  display: inline-block;
  text-align: center;
  background-color: $accent;
  color: $white;
  text-transform: uppercase;
  min-width: 270px;
  padding: 1.2em;
  height: 3.55em;
  border: 1px solid  $accent;
  @include tr(background-color .3s, color .3s);
  @include hover {
    color: $accent;
    background-color: transparent; }
  @include xs {
    min-width: 0;
    width: 100%; }
  .icon {
    font-size: 1.2em;
    position: relative;
    top: .2em;
    margin-right: 5px; }

  &--link {
    border: 0;
    background-color: transparent;
    color: $black; }
  &--full {
    min-width: 0;
    width: 100%; }
  &--back {
    @include before {
      @include icon-arrow(10, 10, 1, currentColor, 45);
      display: inline-block;
      margin-right: 0.5em; } } }
