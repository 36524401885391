$fz:    14px;
$fz-sm: 13px;
$fz-xs: 12px;

$c-text: #474747;
$accent: #e42628;


$black: #000;
$white: #fff;
$white-dark: #f1f1f1;
$white-darken: #e5e5e5;

$gray: #898989;
$gray-light: #7c7c7c;
$gray-lighten: #d3d3d3;

$line-left-1: percentage(170/1450);
$line-left-2: percentage(470/1450);
$line-left-3: percentage(950/1450);
$line-left-4: percentage(1280/1450);

$header-height: 163px;
$header-height-md: 118px;
$header-height-sm: 38px;
$header-height-xs: 35px;

// $header-margin: 97px
// $header-margin-md: 50px
// $header-margin-sm: 50px
// $header-margin-xs: 26px

// $header-margin: 30px
$header-margin: 0px;
$header-margin-md: 0px;
$header-margin-sm: 0px;
$header-margin-xs: 0px;
