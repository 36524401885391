.calculator {
  $this: &;
  text-align: left;
  &__inner {
    @include flex(flex-start, normal, row, wrap); }
  &__left {
    padding-right: 20px;
    @include flex(space-between, normal, column);
    width: percentage(330/1360);
    @include lg {
      padding-right: 0;
      width: 100%;
      order: 1; } }

  &__right {
    padding-left: 20px;
    width: percentage(1030/1360);
    @include lg {
      padding-left: 0;
      width: 100%;
      order: 0;
      margin-bottom: 15px; } }
  &__row {
    @include flex(flex-start, normal, row, wrap);
    margin-left: -20px;
    margin-right: -20px;
    @include lg {
      margin-left: -10px;
      margin-right: -10px; }
    @include notlast {
      margin-bottom: 50px;
      @include xl {
        margin-bottom: 40px; }
      @include lg {
        margin-bottom: 30px; }
      @include md {
        margin-bottom: 15px; } } }
  &__col {
    padding-left: 20px;
    padding-right: 20px;
    width: 33.33333%;
    @include flex(flex-start, normal, column);
    @include lg {
      padding-left: 10px;
      padding-right: 10px; }
    @include md {
      width: 50%;
      margin-bottom: 15px; }
    @include xs {
      width: 100%; } }
  &__col-height {
    #{$this}__field {
      height: 100%; } }
  &__title {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 10px; } }
  &__field {
    // height: 100%
    @include notlast {
      margin-bottom: 15px; } }
  &__block {
    flex-grow: 1; }
  &__imgs {
    @include flex(flex-start, normal, row, wrap);
    margin-left: -10px;
    margin-right: -10px; }
  &__img-wrap {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    @include lg {
      width: 33.33333%; }
    @include xs {
      width: 50%; } }
  &__img {
    position: relative;
    display: block; }
  &__img-img {
    display: block;
    @include cover;
    padding-top: 80%;
    margin-bottom: 10px;
    @include lg {
      padding-top: 60%; } }
  &__img-close {
    background-color: $black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    color: $white;
    @include flex(center, center);
    transform: translate(50%, -50%);
    span {
      @include icon-close(10, 1, currentColor); } }
  &__btn {
    @include lg {
      text-align: center; } } }

