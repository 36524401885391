.checkbox, .radio {
  position: relative;
  padding-left: 1.3em;
  user-select: none;
  display: block;
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    &:checked + span {
      &::after {
        content: '';
        background-color: $accent;
        top: .1em;
        left: 0;
        width: .9em;
        height: .9em;
        position: absolute;
        display: block; } } }
  span {
    &::before {
      content: '';
      width: .9em;
      height: .9em;
      top: .1em;
      left: 0;
      background-color: $gray;
      position: absolute;
      display: block; } }

  &--gallery {
    width: 20px;
    height: 20px;
    display: block;
    input {
      &:checked + span {
        &::after {
          background-color: transparent;
          @include icon-arrow(15, 8, 2, $accent, -45);
          top: 0; }
        &::before {
          border-color: $accent; } } }
    span {
      &::before {
        border: 1px solid transparent; } } } }
