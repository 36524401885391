.form {
  $this: &;
  &__inner {
    padding-left: 15px;
    padding-right: 15px; }
  &__row {
    margin-left: -15px;
    margin-right: -15px;
    @include flex(flex-start, normal, row, wrap); }
  &__col {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    @include notlast {
      margin-bottom: 50px;
      @include xl {
        margin-bottom: 40px; }
      @include lg {
        margin-bottom: 30px; }
      @include md {
        margin-bottom: 20px; }
      @include sm {
        margin-bottom: 10px; } } }
  &__col-50 {
    width: 50%;
    @include xs {
      width: 100%; } }
  &__title {
    margin-bottom: 50px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 10px; } }

  &--hor {
    #{$this}__row {
      margin-left: -2px;
      margin-right: -2px; }
    #{$this}__col {
      width: 33.33333%;
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 0 !important;
      @include xs {
        width: 100%;
        margin-bottom: 4px !important; } } } }

.input, .textarea {
  position: relative;
  text-transform: uppercase;
  input, textarea {
    height: 55px;
    width: 100%;
    background-color: $white;
    border: 0;
    border-bottom: 1px solid  $gray-lighten;
    padding: 18px 0;
    @include tr(border-color .3s);
    @include placeholder {
      text-transform: uppercase; }
    &:focus {
      border-color: $accent; }
    @include lg {
      height: 50px;
      padding: 15px 0; }
    @include md {
      height: 45px;
      padding: 12px 0; }
    @include sm {
      height: 40px; }
    @include xs {
      height: 30px;
      padding: 9px 0 5px; } }
  label {
    &:first-child {
      position: absolute;
      z-index: 1;
      pointer-events: none;
      left: 0;
      top: 18px;
      @include tr(transform .3s);
      @include lg {
        top: 15px; }
      @include md {
        top: 12px; }
      @include xs {
        top: 9px; } } }
  &.has-focus, &.has-text {
    label {
      &:first-child {
        font-size: .75em;
        transform: translate(0, -22px);
        @include lg {
          transform: translate(0, -18px); }
        @include md {
          transform: translate(0, -14px); }
        @include xs {
          transform: translate(0, -11px);
          font-size: .65em; } } } } }

.input {
  &--comb {
    border-bottom: 1px solid  $gray-lighten;
    padding: 10px 0;
    @include flex(space-between, center);
    height: 55px;
    @include lg {
      height: 50px; }
    @include md {
      height: 45px; }
    @include sm {
      height: 40px; }
    @include xs {
      height: 30px; }
    label {
      &:first-child {
        position: static;
        width: 55%; }
      &:last-child {
        width: calc(45% - 90px);
        text-align: right;
        text-transform: none; } }
    input {
      width: 70px;
      height: 30px !important;
      border: 1px solid  $gray-lighten;
      margin-right: 10px;
      margin-left: 10px;
      text-align: center;
      padding: 0 5px;
      @include sm {
        height: 25px !important; } } } }



.textarea {
  textarea {
    overflow: hidden; }
  &--lg {
    height: calc(100% - 15px);
    textarea {
      overflow: auto;
      height: 100% !important;
      min-height: 195px;
      @include lg {
        min-height: 180px; }
      @include sm {
        min-height: 150px; }
      @include xs {
        min-height: 120px; } } } }
