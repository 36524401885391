.about-block {
  $this: &;
  position: relative;
  @include before {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 80%;
    background-color: $accent;
    z-index: 0;
    @include md {
      height: 70%; } }
  &__img {
    @include cover;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: percentage(670/1311);
    padding-top: percentage(670/880);
    @include lg {
      position: relative;
      width: 50%;
      padding-top: 60%; }
    @include md {
      width: 50%; }
    @include sm {
      width: 100%; } }
  &__img-caption {
    position: absolute;
    z-index: 2;
    background-color: $white;
    text-transform: uppercase;
    padding: 35px;
    @include rotate(-90);
    top: 0;
    right: 0;
    transform-origin: 84% 49%;
    @include xl {
      padding: 25px;
      transform-origin: 87% 49%; }
    @include lg {
      padding: 15px;
      transform-origin: 90% 49%; }
    @include xs {
      font-size: .8em;
      transform-origin: 90% 43%; } }
  &__block {
    position: absolute;
    z-index: 2;
    background-color: $white;
    padding: 40px;
    width: 52%;
    @include xl {
      width: 70%; }
    @include lg {
      width: 50%;
      position: relative; }
    @include md {
      position: relative;
      width: 50%;
      transform: translate(0, 0); }
    @include sm {
      width: 100%; }
    @include xs {
      padding: 15px !important; } }

  &__title {
    margin-bottom: 40px;
    @include xl {
      margin-bottom: 30px; }
    @include lg {
      margin-bottom: 25px; }
    @include md {
      margin-bottom: 15px; }
    @include sm {
      margin-bottom: 10px; } }
  &__text {
    margin-bottom: 30px;
    p {
      @include notlast {
        margin-bottom: 20px;
        @include md {
          margin-bottom: 10px; } } }
    ul {
      // list-style-type: disc
      // margin-left: 1.1em
      li {
        &::before {
          content: "\2022";
          display: inline-block;
          margin-right: 5px; } } }
    @include md {
      margin-bottom: 15px; } }
  &--lg {
    padding-right: 70px;
    margin-bottom: 150px;
    @include xl {
      padding-right: 50px; }
    @include lg {
      padding-right: 30px;
      margin-bottom: 120px; }
    @include md {
      padding-right: 20px;
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 15px; }
    @include xs {
      padding-right: 10px; }
    #{$this}__img {
      width: 100%;
      padding-top: percentage(890/1380);
      @include xs {
        padding-top: 65%; } }
    #{$this}__block {
      padding: 40px 100px;
      width: percentage(1072/1380);
      bottom: -75px;
      left: 50%;
      transform: translate(-50%, 0);
      @include xl {
        padding: 40px 70px; }
      @include lg {
        padding: 40px 50px;
        position: absolute; }
      @include md {
        position: relative;
        padding: 30px;
        width: 100%;
        transform: translate(0, 0);
        left: auto;
        bottom: auto; }
      @include sm {
        padding: 20px; } } }
  &--left {
    padding-left: 140px;
    margin-bottom: 100px;
    @include xl {
      padding-left: 70px; }
    @include lg {
      padding-left: 50px;
      @include flex;
      margin-bottom: 50px; }
    @include md {
      padding-left: 30px;
      margin-bottom: 30px; }
    @include sm {
      padding-left: 20px;
      display: block;
      margin-bottom: 15px; }
    @include xs {
      padding-left: 10px; }
    @include before {
      right: auto;
      left: 0;
      top: 50px;
      height: 70%;
      @include xl {
        top: 30px; }
      @include lg {
        top: 20px; } }
    #{$this}__img {}
    #{$this}__block {
      bottom: -45px;
      right: 0;
      padding: 110px 70px;
      @include xl {
        padding: 80px 50px; }
      @include lg {
        padding: 50px;
        bottom: auto;
        right: auto; }
      @include md {
        padding: 30px; }
      @include sm {
        padding: 20px; } } }
  &--right {
    padding-right: 40px;
    padding-top: 170px;
    @include before;
    @include xl {
      padding-right: 30px;
      padding-top: 100px; }
    @include lg {
      padding-right: 20px;
      padding-top: 70px;
      @include flex; }
    @include md {
      padding-top: 40px; }
    @include sm {
      padding-top: 20px;
      display: block; }
    @include xs {
      padding-right: 10px; }
    #{$this}__block {
      bottom: 35px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 110px 45px;
      @include xl {
        padding: 80px 50px; }
      @include lg {
        padding: 50px;
        left: auto;
        transform: translate(0, 0);
        bottom: auto; }
      @include md {
        padding: 30px; }
      @include sm {
        padding: 20px; } }

    #{$this}__img {
        margin-left: auto;
        @include lg {
          margin-left: 0; } } } }
