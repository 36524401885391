.materials {
  // margin-top: -60px
  // +flex(center, normal, row, wrap)
  // margin-left: -10px
  // margin-right: -10px
  // +sm
  //   margin-top: 0
  // &__item
  //   width: 20%
  //   +sm
  //     width: 33.33333%
  //     margin-bottom: 10px
  //   +xs
 }  //     width: 50%

.material-link {
  $this: &;
  @include flex(flex-start, center, column);
  text-align: center;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  @include hover {
    #{$this}__title {
      color: $accent; } }
  &__img {
    // +flex(center, center)
    display: block;
    width: 100%;
    // flex-grow: 1
    // padding-top: 60%
    margin-bottom: 10px;
    padding-top: 60%;
    position: relative;
    img {
      @include vcenter;
      max-height: 100%; }
    // background-repeat: no-repeat
    // background-position: 50% 50%
 }    // background-size: contain
  &__title {
    @extend .title;
    @extend .title--medium;
    @extend .title--white;
    @extend .title--h5;
    @extend .title--ttu;
    @include tr(color .3s); } }
