.blog {
  &__items {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } } }

.blog-article {
  @include flex(flex-start, normal, column);
  height: 100%;
  position: relative;
  @include hover {
    @include before {
      visibility: visible;
      opacity: 1; }
    .link {
      color: $accent;
      @include after {
        border-color: $accent; } } }
  @include before {
    position: absolute;
    z-index: 0;
    background-color: $accent;
    top: -10px;
    right: -10px;
    width: 20%;
    height: 50%;
    @include tr(opacity .3s, visibility .3s);
    visibility: hidden;
    opacity: 0; }
  &__img {
    display: block;
    @include cover;
    width: 100%;
    padding-top: percentage(315/440);
    position: relative;
    z-index: 1; }
  &__content {
    flex-grow: 1;
    @include flex(flex-start, normal, column);
    position: relative;
    z-index: 1;
    background-color: $white;
    padding: 35px;
    @include xl {
      padding: 25px; }
    @include lg {
      padding: 20px; }
    @include md {
      padding: 15px; }
    @include sm {
      padding: 10px; } }
  &__meta {
    display: block;
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__title, &__text {
    display: block;
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    flex-grow: 1; } }

.meta {
  display: block;
  color: $gray-light;
  &__item {
    display: inline-block;
    vertical-align: middle;
    @include notlast {
      margin-right: 25px;
      @include md {
        margin-right: 15px; } }
    .icon {
      position: relative;
      top: .2em;
      margin-right: 5px;
      font-size: 1.2em; } } }

.blog-page {
  figure {
    position: relative;
    padding-left: 115px;
    padding-top: 52%;
    @include xll {
      padding-left: 100px; }
    @include xl {
      padding-left: 80px; }
    @include lg {
      padding-left: 60px; }
    @include md {
      padding-left: 40px; }
    @include sm {
      padding-left: 20px; }
    @include xs {
      padding-left: 10px; }
    @include before {
      position: absolute;
      z-index: 0;
      background-color: $accent;
      left: 0;
      top: 50px;
      height: 70%;
      width: 50%;
      @include lg {
        top: 30px; }
      @include sm {
        top: 20px; }
      @include xs {
        top: 10px; } }
    img {
      // width: 100%
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 115px);
      max-height: 100%;
      object-fit: cover;
      z-index: 1;
      @include xll {
        width: calc(100% - 100px); }
      @include xl {
        width: calc(100% - 80px); }
      @include lg {
        width: calc(100% - 60px); }
      @include md {
        width: calc(100% - 40px); }
      @include sm {
        width: calc(100% - 20px); }
      @include xs {
        width: calc(100% - 10px); } } }
  p, ul, ol, figure, h2, h3 {
    @include notlast {
      margin-bottom: 40px;
      @include lg {
        margin-bottom: 30px; }
      @include md {
        margin-bottom: 15px; } } }
  article {
    margin-bottom: 90px;
    @include xll {
      margin-bottom: 70px; }
    @include xl {
      margin-bottom: 50px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  ul {
    li {
      &::before {
        content: '-';
        display: inline-block;
        margin-right: 5px; } } }
  ol {
    list-style-type: decimal;
    margin-left: 1.2em; }
  h2 {
    @extend .title;
    @extend .title--ttu;
    @extend .title--medium;
    @extend .title--h3; } }

