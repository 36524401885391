@mixin lines($height) {
  position: relative;
  &::before, &::after {
    content: '';
    border-right: 1px solid $gray-lighten;
    position: absolute;
    top: 100%;
    height: $height;
    z-index: 0; }
  &::before {
    left: 8px; }
  &::after {
    right: 8px; } }

@mixin lines-top($height) {
  position: relative;
  &::before, &::after {
    content: '';
    border-right: 1px solid $gray-lighten;
    position: absolute;
    bottom: 100%;
    height: $height;
    z-index: 0; }
  &::before {
    left: 8px; }
  &::after {
    right: 8px; } }

.horizontal-page {
  overflow: hidden;
  opacity: 0;
  z-index: 10;
  @include main-bg;
  &__scroll-to {
    position: absolute;
    z-index: 1; } }

.out-hor {
  $this: &;
  @include flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 665.74vh;
  width: calc(var(--vh, 1vh) * 665.74);
  position: relative;
  &.is-scaled {
    .header-hor {
      &__rect {
        transform: translate(-9vh, -9vh); } }
    #{$this}__bg {
      transform: scale(1.1) translate(0, -50%); }
    .header-hor {
      transform: translate(-10%, 0); }
    .footer-hor {
      transform: translate(40%, 0); }
    .info-block {
      &:nth-child(1) {
        transform: translate(-16vh, -4vh); }
      &:nth-child(2) {
        transform: translate(-16vh, -4vh); }
      &:nth-child(3) {
        transform: translate(1vh, 0); }
      &:nth-child(4) {
        transform: translate(-0.5vh, -5vh); }
      &:nth-child(5) {
        transform: translate(4.7vh, -7vh); }
      &:nth-child(6) {
        transform: translate(15vh, -4vh); } } }
  &__bg {
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100%;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    height: 100%;
    @include ready {
      @include tr(transform 1s); }
    @include rh(485) {
      background-size: 100% 108%; } } }

.header-hor {
  flex: 0 0 percentage(1120/7190);
  height: 100%;
  position: relative;
  z-index: 1;
  @include ready {
    @include tr(transform 1s); }
  &__inner {
    height: 100%;
    max-width: percentage(690/1120);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10vh;
    padding-left: 15px;
    padding-right: 15px;
    @include ready {
      @include tr(transform 1s); }
    @include sm {
      max-width: 100vw; } }
  &__rect {
    margin-bottom: 24vh;
    padding-top: 3.3vh;
    @include tr(transform 1s);
    @include lg {
      margin-bottom: 20vh; }
    @include xs {
      margin-bottom: 15vh; } }
  &__logo {
    // background-color: $white
    @include image('glass-texture.png');
    @include cover;
    text-align: center;
    box-shadow: 7.986px 6.018px 5px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    padding-top: 2.7vh;
    padding-bottom: 2.7vh;
    margin-bottom: 5vh;
    @include lines(5vh); }
  &__logo-wrap {
    @include lines-top(24vh);
    @include lg {
      @include lines-top(20vh); }
    @include xs {
      @include lines-top(15vh); } }
  &__title {
    color: $white;
    background-color: $gray;
    text-align: center;
    text-transform: uppercase;
    // font-size: 30px
    font-size: 2.7vh;
    position: relative;
    margin-bottom: 5vh;
    box-shadow: 7.986px 6.018px 5px 0px rgba(0, 0, 0, 0.15); }
  &__contacts {
    margin-bottom: 14vh;
    @include rh(800) {
      margin-bottom: 7vh; }
    @include lg {
      margin-bottom: 10vh; }
    @include xs {
      margin-bottom: 7vh; } }
  &__scroll {
    @include flex(center, center);
    .icon {
      font-size: 21px;
      // margin-left: 10px
      fill: $black;
      animation: swipe 2s linear infinite;
      transform: translate(50px, 0); } } }

.main-hor {
  flex: 0 0 percentage(5400/7190);
  position: relative;
  z-index: 1; }

.footer-hor {
  flex: 0 0 percentage(670/7190);
  text-align: center;
  position: relative;
  z-index: 1;
  @include tr(transform 1s);
  &__inner {
    padding-top: 15vh;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10vh;
    height: 100%; }
  &__logo {
    margin-bottom: 15vh; }
  &__menu {
    margin-bottom: 5vh;
    @include md {
      font-size: 12px; } }
  &__btn {
    @include md {
      .btn {
        min-width: 0;
        width: 100%; } } } }

.info-block {
  $this: &;
  position: absolute;
  z-index: 1;
  @include tr(transform 1s);
  &:hover {
    #{$this}__btn {
      background-color: $accent;
      color: $white; }
    #{$this}__btn-icon {
      @include rotate(0); }
    #{$this}__block {
      visibility: visible;
      opacity: 1;
      transform: translate(0, -50%); } }
  &__btn {
    width: 40px;
    height: 40px;
    @include inline-flex(center, center);
    background-color: $white;
    box-shadow: 7.986px 6.018px 5px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
    @include tr(color .3s, background-color .3s);
    @include xs {
      width: 30px;
      height: 30px; } }
  &__btn-icon {
    @include icon-close(16, 1, currentColor);
    display: inline-block;
    @include rotate(225);
    @include tr(transform .3s); }
  &__block {
    background-color: $white;
    padding: 30px;
    width: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
    @include ready {
      @include tr(opacity .3s, visibility .3s, transform .3s); }
    @include xs {
      padding: 20px; } }
  &__title {
    margin-bottom: 2vh;
    color: $black;
    font-weight: 500;
    font-size: 21px;
    @include sm {
      font-size: 18px;
      margin-bottom: 1vh; } }
  &__text {
    margin-bottom: 2vh;
    @include sm {
      margin-bottom: 1vh; } }
  &:nth-child(1) {
    top: 50%;
    left: 64vh;
    z-index: 6; }
  &:nth-child(2) {
    top: 53vh;
    left: 104vh;
    z-index: 5; }
  &:nth-child(3) {
    top: 48vh;
    left: 153vh;
    z-index: 4; }
  &:nth-child(4) {
    top: 42vh;
    left: 217vh;
    z-index: 3; }
  &:nth-child(5) {
    top: 20vh;
    left: 273.5vh;
    z-index: 2; }
  &:nth-child(6) {
    top: 72vh;
    left: 372vh;
    z-index: 1; } }

@keyframes swipe {
  0% {
    transform: translate(50px, 0); }
  80% {
    transform: translate(-50px, 0); }
  // 50%
  //   transform: translate(50px, 0)
  // 75%
  //   transform: translate(0px, 0)
  100% {
    transform: translate(50px, 0); } }
