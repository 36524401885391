.services {
  overflow: hidden;
  position: relative;
  @include md {
    padding-top: 25px; }
  &__top {}

  &__title {}

  &__blocks {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    padding-bottom: 50px;
    @include xl {
      padding-top: 40px;
      padding-bottom: 40px; }
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; }
    @include md {
      padding-top: 20px;
      padding-bottom: 20px; } }
  &__block {
    position: relative;
    z-index: 1;
    max-width: 1240px;
    @include lg {
      margin-left: 0 !important; } }
  &__block-offset-1 {
    margin-left: 8%; }
  &__block-right {
    margin-left: auto; } }

.service-block {
  $this: &;
  @include flex();
  margin-bottom: 50px;
  @include xl {
    margin-bottom: 40px; }
  @include lg {
    margin-bottom: 30px !important; }
  @include sm {
 }    // margin-bottom: 20px
  @include xs {
    // margin-bottom: 10px
    display: block;
    background-color: $white;
    box-shadow: 0 7px 19px 0 rgba(0,0,0,.17); }
  &__content {
    @include flex(center, normal, column);
    width: 50%;
    padding-right: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
    @include xl {
      padding-right: 60px; }
    @include lg {
      padding-right: 40px; }
    @include md {
      padding-right: 30px; }
    @include sm {
      padding-right: 15px; }
    @include xs {
      width: auto;
      padding-left: 15px;
      padding-right: 15px; } }
  &__img {
    @include cover;
    // background-color: $gray-lighten
    padding-top: 30%;
    box-shadow: 0px 7px 19px 0px rgba(0, 0, 0, 0.17);
    // width: percentage(590/1240)
    width: 50%;
    @include xs {
      width: auto;
      padding-top: 50%; }
    @include xss {
      padding-top: 60%; } }
  &__title {
    margin-bottom: 50px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 10px; } }
  &__text {
    margin-bottom: 40px;
    max-width: 570px;
    @include xl {
      margin-bottom: 30px; }
    @include lg {
      margin-bottom: 20px; }
    @include md {
      margin-bottom: 10px; } }

  &--img-left {
    #{$this}__content {
      padding-left: 80px;
      padding-right: 15px;
      @include xl {
        padding-left: 60px; }
      @include lg {
        padding-left: 40px; }
      @include md {
        padding-left: 30px; }
      @include sm {
        padding-left: 15px; } } }
  &--img-contain {
    #{$this}__img {
      background-size: contain;
      box-shadow: none; } }
  &--mb-0 {
    margin-bottom: 0 !important; } }

.services-links {
  margin-left: -15px;
  margin-right: -15px;
  @include flex(center, normal, row, wrap);
  @include md {
    margin-left: -10px;
    margin-right: -10px; }
  @include xs {
    margin-left: -5px;
    margin-right: -5px; }
  &__item {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.33333%;
    margin-bottom: 50px;
    opacity: 0;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px; }
    @include xs {
      padding-left: 5px;
      padding-right: 5px;
      width: 50%;
      margin-bottom: 10px; } } }

.service-link {
  $this: &;
  display: block;
  @include hover {
    #{$this}__img {
      box-shadow: 0px 7px 19px 0px rgba(0, 0, 0, 0.17);
      @include before {
        visibility: visible;
        opacity: 1; }
      .service-link__link {
        visibility: visible;
        opacity: 1; } } }

  &__img {
    position: relative;
    font-size: 0;
    // +cover
    padding-top: 70%;
    margin-bottom: 20px;
    background-color: $white;
    border: 1px solid  $gray-lighten;
    display: block;
    @include tr(box-shadow .3s);
    @include before {
      @include coverdiv;
      background-color: rgba($black , .7);
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      @include tr(opacity .3s, visibility .3s); }
    @include md {
      margin-bottom: 10px; }
    img {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__title {
    display: block; }
  &__link {
    visibility: hidden;
    opacity: 0;
    @include vcenter;
    width: 100%;
    text-align: center;
    color: $white;
    z-index: 2;
    font-size: $fz;
    @include tr(opacity .3s, visibility .3s);
    @include hover {
      color: $white; }
    @include sm {
      font-size: $fz-sm; } }
  &--img-contain {
    #{$this}__img {
      img {
        top: 10px;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        height: calc(100% - 20px);
        width: auto;
        object-fit: initial; } } }

  &--hover-no-cover {
    @include hover {
      #{$this}__img {
        // border-color: transparent
        @include before {
          visibility: hidden;
          opacity: 0; } } } } }

